var h4toAccordion = function (startNode) {
    var startNode = startNode || "text-content";

    //0
    var first_block_start = $("." + startNode + " > *").first();

    var block_header = [];
    block_header.push(first_block_start);
    first_block_start.nextUntil("h4").each(function (ind, value) {
        block_header.push(value);
        $(this).remove();
    });

    var div_accordion = $('<div></div>').attr({
        'id': 'accordion0',
        'role': 'tablist',
        'aria-multiselectable': 'true'
    });


    $("." + startNode + " > h4").each(function (index) {
        var header_text = $(this).text();
        var content_content = [];

        var isExit = false; //We exit from a block at last h4

        $(this).nextUntil("h4").each(function (ind, value) {
            if ($(this).is("pre")) {
                isExit = true;
                return false;
            } else {
                content_content.push($(value));
            }
        });


        if (header_text !== "") { //Dont add empty h4 into card blocks
            var div_card = $('<div></div>').addClass("card mb-2 accordion-item");

            //Card header
            var a_media = $("<a></a>").attr({
                'class': 'media collapsed',
                'data-toggle': 'collapse',
                'data-parent': '#accordion0',
                'href': '#collapse0' + index,
                'aria-expanded': 'false',
                'aria-controls': 'collapse0' + index
            });

            var div_media_body = $("<div></div>").attr({
                class: 'media-body align-self-center'
            });
            var h4_media_heading = $("<h4></h4>").addClass('media-heading').text(header_text);

            var div_d_flex = $("<div></div>").addClass('d-flex align-self-center icon-wrap');
            var span = $("<span></span>").addClass('icon-small-chevron');

            //Collapse
            var div_collapse = $('<div></div>').attr({
                'id': 'collapse0' + index,
                'class': 'in collapse',
                'role': 'tabpanel',
                'aria-labelledby': 'heading0' + index
            });

            var div_card_block = $('<div></div>').addClass('card-block');

            $(div_accordion).append(div_card);
            div_card.append(a_media);
            a_media.append(div_media_body);
            div_media_body.append(h4_media_heading);

            a_media.append(div_d_flex);
            div_d_flex.append(span);

            div_card.append(div_collapse);
            div_collapse.append(div_card_block);

            $.each(content_content, function (ind, value) {
                div_card_block.append($(value));
            });

            if (isExit) {
                $(this).remove();
                return false;
            }
        }
        $(this).remove();
    });

    $.each(block_header, function (ind, value) {
        var clone = $(value).clone();
        first_block_start.before(clone);
    });

    if (first_block_start.children().length >= 0) {
        first_block_start.after(div_accordion);
    }
    first_block_start.remove();

    //1...n
    var pre = $("." + startNode + " > pre");
    pre.each(function (index) {
        var block_header = [];

        $(this).nextUntil("h4").each(function (ind, value) {
            if (ind == 0) {
                var placeholder_br = "<p><br></p>";
                block_header.push(placeholder_br);
            }
            block_header.push($(value));
            $(this).remove();
        });

        var div_accordion = $('<div></div>').attr({
            'id': 'accordion' + index + 1,
            'role': 'tablist',
            'aria-multiselectable': 'true'
        });

        $(this).nextAll().each(function (al_index) {
            if ($(this).is("h4") && !$(this).first().is("br")) {

                var header_text = $(this).text();
                var content_content = [];


                var isExit = false;
                $(this).nextUntil("h4").each(function (ind, value) {
                    if ($(this).is("pre")) {
                        isExit = true;
                        return false;
                    } else {
                        content_content.push($(value));
                    }
                });

                if (header_text !== "") {

                    var div_card = $('<div></div>').addClass("card mb-2 accordion-item");

                    //Card header
                    var a_media = $("<a></a>").attr({
                        'class': 'media collapsed',
                        'data-toggle': 'collapse',
                        'data-parent': '#accordion' + index + 1,
                        'href': '#collapse' + (index + 1) + al_index,
                        'aria-expanded': 'false',
                        'aria-controls': 'collapse' + (index + 1) + al_index
                    });

                    var div_media_body = $("<div></div>").attr({
                        class: 'media-body align-self-center'
                    });

                    var h4_media_heading = $("<h4></h4>").addClass('media-heading').text(header_text);


                    var div_d_flex = $("<div></div>").addClass('d-flex align-self-center icon-wrap');
                    var span = $("<span></span>").addClass('icon-small-chevron');

                    //Collapse
                    var div_collapse = $('<div></div>').attr({
                        'id': 'collapse' + (index + 1) + al_index,
                        'class': 'in collapse',
                        'role': 'tabpanel',
                        'aria-labelledby': 'heading' + (index + 1) + al_index
                    });

                    var div_card_block = $('<div></div>').addClass('card-block');


                    $(div_accordion).append(div_card);
                    div_card.append(a_media);
                    a_media.append(div_media_body);
                    div_media_body.append(h4_media_heading);

                    a_media.append(div_d_flex);
                    div_d_flex.append(span);

                    div_card.append(div_collapse);
                    div_collapse.append(div_card_block);


                    $.each(content_content, function (ind, value) {
                        div_card_block.append($(value));
                    });

                    if (isExit) {
                        $(this).remove();
                        return false;
                    }
                }
                $(this).remove();
            }
        });

        var pre_i = $(this);
        $.each(block_header, function (ind, value) {
            pre_i.before($(value));
        });

        if (div_accordion.children().length > 0) {
            $(this).after(div_accordion);
        }
    });
    pre.remove();


}